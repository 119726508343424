document.addEventListener('DOMContentLoaded', () => {
	let passive = false;
	try {
		const opts = Object.defineProperty({}, 'passive', {
			get() {
				passive = { passive: true };
			}
		});
		window.addEventListener("testPassive", null, opts);
		window.removeEventListener("testPassive", null, opts);
	} catch (e) {}

	const navMenu = document.getElementById('nav-menu');
	const navBurger = document.getElementById('nav-burger');

	navBurger.addEventListener('click', () => {
		navBurger.classList.toggle('is-active');
		navMenu.classList.toggle('is-active');
	}, passive);

	const sections = [];
	const checkNav = () => {
		let a = false;
		for (let { nav, el } of sections) {
			let boundary = el.offsetTop + el.offsetHeight;
			while (el.offsetParent) {
				el = el.offsetParent;
				boundary += el.offsetTop;
			}
			const v = !a && window.scrollY < boundary;
			nav.classList.toggle('is-active', v);
			if (v) {
				a = true;
			}
		}
	};

	document.querySelectorAll('.navbar-item').forEach(el => {
		const section = document.getElementById(el.href.split('#')[1]);

		el.addEventListener('click', evt => {
			evt.preventDefault();
			history.pushState({}, null, el.href);

			navBurger.classList.remove('is-active');
			navMenu.classList.remove('is-active');

			section.scrollIntoView({ behavior: 'smooth', block: 'start' });
		});

		sections.push({ nav: el, el: section });
	});

	const html = document.getElementsByTagName('html')[0];
	const bookModal = document.getElementById('book-modal');
	const bookFrame = document.getElementById('book-frame');

	document.querySelectorAll('.book').forEach(el => {
		el.addEventListener('click', () => {
			html.classList.toggle('book-modal-open');
			bookModal.classList.toggle('is-active');
		});
	});

	document.querySelectorAll('#book-modal .modal-background,#book-modal-close').forEach(el => {
		el.addEventListener('click', () => {
			bookModal.classList.toggle('is-active');
			html.classList.toggle('book-modal-open');
		}, passive);
	});

	window.addEventListener('scroll', checkNav);
	window.addEventListener('resize', checkNav);
	window.addEventListener('load', () => {
		checkNav();
		bookFrame.setAttribute('src', 'https://squareup.com/appointments/buyer/widget/zwlztsn3p4lor3/G7W5SHFQAW8Q7');
	});
});
